@import "../../styles/theme-styles.scss";

@mixin respond($breakpoint) {
  @if ($breakpoint == medium) {
    @media (max-width: 1025px) {
      @content;
    }
  }
  @if ($breakpoint == small) {
    @media (max-width: 470px) {
      @content;
    }
  }
}

.transportContainer {
  position: relative;
  .filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .butonOrange {
      @include btn-orange;
    }
  }
  .number-day {
    position: absolute;
    left: $margin-base;
    top: 25px;
    @include respond(small) {
      left: 8px;
    }
  }
  .textDescriptionBooking {
    @include font-level-8;
    margin-left: 0;
    font-weight: bold !important;
    color: $gray-3;
    @include respond(medium) {
      display: none;
    }
  }
  .containerButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    @include containerButtons;
    position: sticky;
    right: 12px;
    bottom: 12px;
    margin-left: -16px;
    width: 100%;
    @include respond(medium) {
      justify-content: center;
    }
    :disabled {
      opacity: .6;
    }
  }
}
