@import "../../../styles/theme-styles.scss";

@mixin respond($breakpoint) {
  @if ($breakpoint == medium) {
    @media (max-width: 1025px) {
      @content;
    }
  }
  @if ($breakpoint == small) {
    @media (max-width: 470px) {
      @content;
    }
  }
}

.modalAllReservationContainer {    
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  h2 {
    @include font-level-4;
    margin: 0;    
    @include respond(medium) {
      font-size: 24px;
      font-weight: 700;
    }
  }
  h4 {
    @include font-level-6;
    margin: 0;
    text-align: center;
    @include respond(medium) {
      @include font-level-10;
      text-align: inherit;
    }
  }
  p {
    margin-top: $margin-base;
    @include font-level-5;
  }
}