@import "../../styles/theme-styles.scss";

@mixin respond($breakpoint) {
  @if ($breakpoint == medium) {
    @media (max-width: 1025px) {
      @content;
    }
  }
  @if ($breakpoint == small) {
    @media (max-width: 470px) {
      @content;
    }
  }
}

.overlay {
  position: absolute;
  background-color: black;
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
}

.modal {
  box-sizing: border-box;
  position: sticky;
  background: $white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 8px;
  margin-top: 16px;
  z-index: 17;
  position: relative;
  margin-bottom: 24px;
  max-height: -webkit-fill-available;
  max-width: 800px;
  width: calc(100% - 40px);
  &-header {
    display: flex;
    justify-content: space-between;
    @include font-level-5;
    padding: 1.5rem;

    background: white;
    * {
      margin: 0;
    }
    &-close {
      height: 0;

      padding: 0;
      border: none;

      background: none;

      cursor: pointer;

      * {
        stroke: $gray-1;
      }
    }
  }
  &-body {
    max-height: 500px;
    overflow-y: auto;

    padding: 0 1.5rem;
    margin: $margin-milli 0 $margin-basedo 0;
    &::-webkit-scrollbar {
      width: calc($margin-milli / 2);

      // display: inline-block;
      cursor: pointer;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $gray-2;

      cursor: pointer;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $gray-2;
    }
  }

  &-footer {
    display: flex;
    width: 100%;

    box-sizing: border-box;
    padding: 0 $margin-basedo $margin-base $margin-basedo;

    background-color: white;
  }

  &-size-m {
    max-width: 480px;
  }
  &-size-l {
    max-width: 800px;
  }
  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 16;
    display: flex;
    background: rgba(0, 0, 0, 0.7);
    justify-content: center;
    align-items: center;
  }
  .material-icons {
    cursor: pointer;
    border: none;
    position: sticky;
    left: 98%;
    top: 2%;
    font-weight: bolder;
    padding: 4px;
    @include font-level-5;
    transition: transform 0.2s;
    &:hover {
      transform: scale(1.3);
    }
  }
}
