.container{
    width: 100%;
    .overlay{
        position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // position: sticky;
    z-index: 999;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.0);
    display: flex;
    justify-content: center;
    align-items: center;
    }
    .containerLoading {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    img{
        left: 40%;
        width: 80px;
    }
}