@import "../../../styles/theme-styles.scss";
.header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  h1 {
    @include font-level-4;
  }
  h4 {
    @include font-level-6;
    margin: 0;
  }
}
