@import "../../../../styles/theme-styles.scss";

@mixin respond($breakpoint) {
  @if ($breakpoint == medium) {
    @media (max-width: 1025px) {
      @content;
    }
  }
  @if ($breakpoint == small) {
    @media (max-width: 470px) {
      @content;
    }
  }
}

.myReservations {
  width: 100%;
  margin-top: 16px;
  border-top: 0.6px solid #ccc;
  .reservationInfo {
    padding-top: 16px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    @include respond(medium) {
      align-items: flex-start;
      flex-direction: column;
    }
    div {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      @include respond(medium) {
        width: 100%;
      }
    }
    span {
      @include font-level-6;
      margin-bottom: 8px;
      @include respond(medium) {
        @include font-level-10;
      }
    }
  }
  .callToAction {
    display: flex;
    justify-content: flex-start;
    margin-top: $margin-milli;      
    @include respond(medium) {
      flex-direction: column;
    }
    button, a, .canceled-status {
      text-align: inherit;
      width: 50%;
      @include btn-orange;
      padding: 0 !important;
      font-size: 14px;
      @include respond(medium) {
        width: 100%;
        margin-top: 12px;
        font-size: 14px;
      }
    }
    p {
      @include font-level-7;
      width: 50%;
      margin: 0;
      @include respond(medium) {
        width: 100%;
        @include font-level-11;
      }
    }
  }
}
