@import "../../../styles/theme-styles.scss";

@mixin respond($breakpoint) {
  @if ($breakpoint == medium) {
    @media (max-width: 1025px) {
      @content;
    }
  }
  @if ($breakpoint == small) {
    @media (max-width: 470px) {
      @content;
    }
  }
}

.containerFilters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  @include respond(medium) {
    align-items: flex-start;
    flex-direction: column-reverse;
    margin-top: $margin-milli;
  }
  .butonOrange {
    @include btn-orange;
    font-size: 14px;
    padding: 0;
    @include respond(medium) {
      margin-bottom: 0;
      margin-top: $margin-milli;
      padding-left: 0;
    }
  }
  .filters {
    display: inline-flex;
    @include respond(medium) {
      margin-top: $margin-small;
      width: 100%;
    }
    button {
      @include btn-filters;
      &.active {
        background: $orange !important;
        color: $white !important;
      }
      @include respond(medium) {
        button {
          width: 10%;
        }
        &.showMonthMobile {
          width: 90%;
          background: $orange !important;
          color: $white !important;
        }
        &.showArrowMobile {
          width: 5%;
          background: transparent !important;
          color: $gray-1 !important;
          content: "> ";
        }
      }
    }
  }
}
