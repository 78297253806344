// Colors

$black: #171242;
$white: #ffffff;
$gray-1: #585757;
$gray-2: #f4f4f4;
$gray-3: #939393;
$gray-disabled: #c1c1c1;
$orange: #ff5730;
$green-1: #e9f9e4;
$green-2: #00a92f;
$green-3: #d5ffd7;
$red-1: #f2f2f2;
$red-2: #E92C2C;
$red-3: #FFEBEB;
$red-fail: #a82e2e;

$primary-hover: #00c4b4;
$primary-active: #02d6c5;

// fontSize

// @mixin font-level-1 {

//   font-size: calc(125px + (380 - 125) * ((100vw - 1200px) / (3840 - 1200)));
//   font-weight: 700;
//   line-height: 100%;
//   letter-spacing: -0.05em;
//   color: $gray-1;
//   @media screen and (max-width: 1024px) {
//     font-size: calc(72px + (220 - 72) * ((100vw - 300px) / (1024 - 300)));
//   }
// }

@mixin font-level-1 {
  font-size: calc(50px + (200 - 60) * ((100vw - 1200px) / (3840 - 1200)));
  font-weight: 700;
  line-height: 0%;
  color: $red;
  @media screen and (max-width: 1024px) {
    // font-size: calc(46px + (106 - 46) * ((100vw - 300px) / (1024 - 300)));
    font-size: 46px;
  }
}

@mixin font-level-2 {
  font-size: calc(40px + (160 - 50) * ((100vw - 1200px) / (3840 - 1200)));
  font-weight: 700;
  line-height: 118%;
  color: $gray-1;
  @media screen and (max-width: 1024px) {
    // font-size: calc(27px + (62 - 27) * ((100vw - 300px) / (1024 - 300)));
    font-size: 27px;
  }
}

@mixin font-level-3 {
  font-family: "Helvetic_Light";
  font-size: calc(32px + (124 - 38) * ((100vw - 1200px) / (3840 - 1200)));
  font-weight: 400;
  line-height: 116%;
  color: $gray-1;
  @media screen and (max-width: 1024px) {
    // font-size: calc(20px + (44 - 20) * ((100vw - 300px) / (1024 - 300)));
    font-size: 20px;
    line-height: 120%;
  }
}

@mixin font-level-4 {
  font-family: "Helvetic_Light";
  // font-size: calc(26px + (88 - 27.5) * ((100vw - 1200px) / (3840 - 1200)));
  font-size: 26px;
  font-weight: 700;
  line-height: 116%;
  color: $gray-1;
  @media screen and (max-width: 1024px) {
    // font-size: calc(34px + (88 - 27.5) * ((100vw - 1200px) / (3840 - 1200)));
    font-size: 26px;
  }
}

@mixin font-level-5 {
  font-family: "Helvetic_Light";
  // font-size: calc(18px + (70 - 23.4) * ((100vw - 1200px) / (3840 - 1200)));
  font-size: 18px;
  font-weight: 700;
  line-height: 116%;
  color: $gray-1;
  @media screen and (max-width: 1024px) {
    // font-size: calc(26px + (88 - 27.5) * ((100vw - 1200px) / (3840 - 1200)));
    font-size: 26px;
  }
}

@mixin font-level-6 {
  font-family: "Helvetic_Light";
  // font-size: calc(14px + (56.2 - 17.5) * ((100vw - 1200px) / (3840 - 1200)));
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  color: $gray-1;
  @media screen and (max-width: 1024px) {
    // font-size: calc(24px + (88 - 27.5) * ((100vw - 1200px) / (3840 - 1200)));
    font-size: 14px;
  }
}

@mixin font-level-7 {
  font-family: "Helvetic_Light";
  // font-size: calc(12px + (45 - 15.5) * ((100vw - 1200px) / (3840 - 1200)));
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
  color: $gray-1;
  @media screen and (max-width: 1024px) {
    // font-size: calc(20px + (88 - 27.5) * ((100vw - 1200px) / (3840 - 1200)));
    font-size: 20px;
  }
}

@mixin font-level-8 {
  font-family: "Helvetic_Light";
  // font-size: calc(10px + (44 - 14) * ((100vw - 1200px) / (3840 - 1200)));
  font-size: 10px;
  font-weight: 400;
  line-height: 114%;
  color: $gray-1;
  @media screen and (max-width: 1024px) {
    // font-size: calc(18px + (88 - 27.5) * ((100vw - 1200px) / (3840 - 1200)));
    font-size: 18px;
  }
}

@mixin font-level-9 {
  font-family: "Helvetic_Light";
  // font-size: calc(8px + (37 - 11.6) * ((100vw - 1200px) / (3840 - 1200)));
  font-size: 8px;
  font-weight: 400;
  line-height: 152%;
  color: $gray-1;
  @media screen and (max-width: 1024px) {
    // font-size: calc(16px + (88 - 27.5) * ((100vw - 1200px) / (3840 - 1200)));
    font-size: 16px;
  }
}

@mixin font-level-10 {
  font-family: "Helvetic_Light";
  // font-size: calc(7px + (25 - 10) * ((100vw - 1200px) / (3840 - 1200)));
  font-size: 7px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.02rem;
  color: $gray-1;
  @media screen and (max-width: 1024px) {
    // font-size: calc(15px + (88 - 27.5) * ((100vw - 1200px) / (3840 - 1200)));
    font-size: 15px;
  }
}

@mixin font-level-11 {
  font-family: "Helvetic_Light";
  // font-size: calc(6px + (25 - 10) * ((100vw - 1200px) / (3840 - 1200)));
  font-size: 6px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.02rem;
  color: $gray-1;
  @media screen and (max-width: 1024px) {
    // font-size: calc(14px + (88 - 27.5) * ((100vw - 1200px) / (3840 - 1200)));
    font-size: 14px;
  }
}

// Lines

$line-1: calc(1px + (2 - 1) * ((100vw - 1200px) / (3840 - 1200)));
$line-2: calc(2px + (4 - 2) * ((100vw - 1200px) / (3840 - 1200)));
$line-3: calc(3px + (8 - 3) * ((100vw - 1200px) / (3840 - 1200)));
$line-3-mobile: calc(2px + (6 - 2) * ((100vw - 300px) / (1024 - 300)));
$line-4: calc(3px + (6 - 3) * ((100vw - 1200px) / (3840 - 1200)));
$line-4-mobile: calc(2px + (4 - 2) * ((100vw - 300px) / (1024 - 300)));

// Buttons

@mixin btn-filters {
  position: relative;
  @include font-level-8;
  font-weight: 700;
  color: $gray-3;
  text-decoration: none;
  line-height: 100%;
  border: $line-1 solid $gray-1;
  border-radius: 4px;
  padding: calc(8px + (22 - 7) * ((100vw - 1200px) / (3840 - 1200)))
    calc(24px + (40 - 14) * ((100vw - 1200px) / (3840 - 1200)))
    calc(8px + (22 - 7) * ((100vw - 1200px) / (3840 - 1200)))
    calc(24px + (76 - 22) * ((100vw - 1200px) / (3840 - 1200)));
  transition: all 0.3s;
  cursor: pointer;
  margin-right: 8px;
  @media screen and (max-width: 1024px) {
    padding: calc(6px + (18 - 6) * ((100vw - 300px) / (1024 - 300)))
      calc(16px + (24 - 9) * ((100vw - 300px) / (1024 - 300)))
      calc(6px + (18 - 6) * ((100vw - 300px) / (1024 - 300)))
      calc(16px + (24 - 9) * ((100vw - 300px) / (1024 - 300)));
    margin-bottom: calc(5px + (12 - 5) * ((100vw - 300px) / (1024 - 300)));
  }
  @media screen and (min-width: 1024px) {
    &:active {
      transform: scale(0.98);
    }
  }
}

@mixin btn-orange {
  border: 0;
  outline: none;
  background: transparent;
  position: relative;
  @include font-level-8;
  font-weight: 700;
  color: $orange;
  text-decoration: none;
  letter-spacing: 0.02rem;
  line-height: 100%;
  padding: calc(8px + (22 - 7) * ((100vw - 1200px) / (3840 - 1200)))
    calc(24px + (40 - 14) * ((100vw - 1200px) / (3840 - 1200)))
    calc(8px + (22 - 7) * ((100vw - 1200px) / (3840 - 1200)))
    calc(24px + (76 - 22) * ((100vw - 1200px) / (3840 - 1200)));
  transition: all 0.3s;
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    padding: calc(6px + 12 * (100vw - 300px) / 724)
      calc(9px + 15 * (100vw - 300px) / 724)
      calc(6px + 12 * (100vw - 300px) / 724)
      calc(9px + 15 * (100vw - 300px) / 724);
    margin-bottom: calc(5px + (12 - 5) * ((100vw - 300px) / (1024 - 300)));
  }
}

@mixin containerButtons {
  display: flex;
  justify-content: end;
  .buttonCancelar {
    @include font-level-6;
    font-family: "Helvetic_Medium";
    font-weight: 400;;
    padding: 8px 24px;
    margin-left: 12px;
    border-radius: 4px;
    border: 1px solid black;
    text-transform: uppercase;
    background: white;
    color: #062337;
    cursor: pointer;
  }
  .buttonContinuar {
    @include font-level-6;
    font-family: "Helvetic_Medium";
    font-weight: 400;;
    padding: 8px 24px;
    margin-left: 12px;
    border: 1px solid black;
    text-transform: uppercase;
    background: #062337;
    color: white;
    cursor: pointer;
  }
}

@mixin button-default {
  padding: 8px 24px;
  border-radius: 4px;
  @include font-level-9;
  background: #062337;
  color: white;
  cursor: pointer;
}

// Border Radius
$redondeado: 4px;
$redondeadobig: 8px;

// a href opacity
$a-opacity: 0.7;

// Margin
$margin-milli: 8px;
$margin-small: 12px;
$margin-base: 16px;
$margin-basedo: 20px;
$margin-kilo: 24px;
$margin-mega: 32px;
$margin-giga: 40px;
$margin-tera: 48px;
$margin-xtera: 56px;
$margin-xterabig: 64px;

// Gaps
$gap-base: 16px;
$gap-kilo: 24px;

// Breakpoints
$break-sm: 640px;
$break-md: 960px;
$break-lg: 1280px;

// Default Input

@mixin default-input {
  padding: 10px;
  outline: none;
  border: none;
  background: #f4f4f4;
  margin-right: 16px;
}

.card {
  padding: 16px;
  border-radius: 3px;
  width: 100%;
  margin-bottom: 16px;
  &--white {
    background: $white;
    border: 1px solid #d9d9d9;
  }
}
