@import "../../../styles/theme-styles.scss";

@mixin respond($breakpoint) {
  @if ($breakpoint == medium) {
    @media (max-width: 1025px) {
      @content;
    }
  }
  @if ($breakpoint == small) {
    @media (max-width: 470px) {
      @content;
    }
  }
}

.modalAlert {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .containerButtons {
    width: 100%;
    @include containerButtons;
    display: flex;
    justify-content: center;
  }
  p {
    @include font-level-11;
  }
}
